import http from "./http";
export default (driverId) => ({
  async index(filters) {
    return http
      .index("drivers", filters)
      .then((json) => ({ drivers: json }))
      .catch(async (e) => ({ drivers: null, error: await e }));
  },
  async get() {
    return http
      .get("drivers", driverId)
      .then((json) => ({ driver: json }))
      .catch(async (e) => ({ driver: null, error: await e }));
  },
  async create(data) {
    return http
      .post("drivers", data)
      .then((json) => ({ driver: json }))
      .catch(async (e) => ({ driver: null, error: await e }));
  },
  async update(data) {
    return http
      .put("drivers", driverId, data)
      .then((json) => ({ driver: json }))
      .catch(async (e) => ({ driver: null, error: await e }));
  },
  async delete() {
    return http
      .delete("drivers", driverId)
      .then((json) => ({ driver: json }))
      .catch(async (e) => ({ driver: null, error: await e }));
  },
  Control: (controlId) => ({
    async index(filters) {
      const url = driverId ? `drivers/${driverId}/controls` : `controls`;
      return http
        .index(url, filters)
        .then((json) => ({ controls: json }))
        .catch(async (e) => ({ controls: null, error: await e }));
    },
    async get() {
      return http
        .get(`drivers/${driverId}/controls`, controlId)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`drivers/${driverId}/controls`, data)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`drivers/${driverId}/controls`, controlId, data)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`drivers/${driverId}/controls`, controlId)
        .then((json) => ({ control: json }))
        .catch(async (e) => ({ control: null, error: await e }));
    },
  }),
  Supply: (supplyId) => ({
    async index(filters) {
      const url = driverId ? `drivers/${driverId}/supplies` : `supplies`;
      return http
        .index(url, filters)
        .then((json) => ({ supplies: json }))
        .catch(async (e) => ({ supplies: null, error: await e }));
    },
    async get() {
      return http
        .get(`drivers/${supplyId}/supplies`, supplyId)
        .then((json) => ({ supply: json }))
        .catch(async (e) => ({ supply: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`drivers/${supplyId}/supplies`, data)
        .then((json) => ({ supply: json }))
        .catch(async (e) => ({ supply: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`drivers/${supplyId}/supplies`, supplyId, data)
        .then((json) => ({ supply: json }))
        .catch(async (e) => ({ supply: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`drivers/${supplyId}/supplies`, supplyId)
        .then((json) => ({ supply: json }))
        .catch(async (e) => ({ supply: null, error: await e }));
    },
  }),
});
